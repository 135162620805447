import { format, parseISO } from 'date-fns';
import { isEqual } from 'lodash';
import { multiSelectProperties, singleSelectProperties } from './general';

const _getCommonProps = (input) => {
	const output = {};
	[
		'firstName',
		'lastName',
		'emailAddress',
		'weddingDate',
		'maxGuestCount',
		'minGuestCount',
		'receptionVenue',
		'requireCeremonyVenue',
	].forEach((key) => {
		if (key in input) {
			output[key] = input[key];
		}
	});

	return output;
};

const _getLastRequestVenuePicker = (lastRequest) => {
	if (lastRequest.receptionVenue.id) {
		return {
			receptionVenue: {
				storefront: lastRequest.receptionVenue,
				location: {
					city: lastRequest.receptionVenue.city,
					state: lastRequest.receptionVenue.state,
				},
			},
		};
	}
	if (lastRequest.receptionVenue.sourceId) {
		return {
			receptionVenue: {
				googlePlace: lastRequest.receptionVenue,
				location: {
					city: lastRequest.receptionVenue.city,
					state: lastRequest.receptionVenue.state,
				},
			},
		};
	}
	if (lastRequest.receptionVenue.city && lastRequest.receptionVenue.state) {
		return {
			receptionVenue: {
				location: {
					city: lastRequest.receptionVenue.city,
					state: lastRequest.receptionVenue.state,
				},
			},
		};
	}
	return null;
};
const _hasKeys = (input, keys) => {
	let hasKey = true;

	keys.forEach((key) => {
		hasKey = hasKey && key in input;
	});

	return hasKey;
};

// matches value against schema
const _lastRequestFindByValue = (config, key, value) =>
	config[key] && config[key].values.find((item) => isEqual(item.value, value));

const _lastRequestFindByValueMinMax = (input, config, key, keys) => {
	const value = {};
	value[keys.min] = input[keys.min];
	value[keys.max] = input[keys.max];

	return _lastRequestFindByValue(config, key, value);
};

const _lastRequestFindByValues = (input, config, key) => {
	if (input[key] && config[key] && config[key].values) {
		return config[key].values.filter((configValue) =>
			input[key].find((inputValue) => isEqual(configValue.value, inputValue)),
		);
	}
	return [];
};

const _lastRequestAssignMaxMinValues = (lastRequest, properties, output) => {
	[
		{
			key: 'guest_count',
			min: 'minGuestCount',
			max: 'maxGuestCount',
			input: lastRequest,
		},
		{
			key: 'budget',
			min: 'minBudget',
			max: 'maxBudget',
			input: lastRequest.details,
		},
		{
			key: 'howManyNeedServices',
			min: 'minNeedingServices',
			max: 'maxNeedingServices',
			input: lastRequest.details,
		},
	].forEach((item) => {
		if (item.input && _hasKeys(item.input, [item.min, item.max])) {
			const selected = _lastRequestFindByValueMinMax(
				item.input,
				properties,
				item.key,
				{
					min: item.min,
					max: item.max,
				},
			);
			if (selected) {
				output[item.key] = selected;
			}
			delete item.input[item.min];
			delete item.input[item.max];
		}
	});
};

// convert last request into form data
const loadLastRequest = (request, category, properties, ui) => {
	const output = {};
	if (!request) {
		return output;
	}

	let lastRequest = {};
	if (request.source && request.source.categoryCode === category) {
		// deep cloning
		lastRequest = JSON.parse(JSON.stringify(request));
	} else {
		lastRequest = _getCommonProps(request);
	}
	// remove source it served it's purpose
	delete lastRequest.source;

	const filteredSingleSelectProperties = singleSelectProperties(ui).filter(
		(key) => !['guest_count', 'budget', 'howManyNeedServices'].includes(key),
	);

	if (lastRequest.receptionVenue) {
		const venue_picker = _getLastRequestVenuePicker(lastRequest);

		if (venue_picker !== null) {
			output.venue_picker = venue_picker;
		}
		if ('requireCeremonyVenue' in lastRequest) {
			output.venue_picker.requireCeremonyVenue =
				lastRequest.requireCeremonyVenue;
		}

		delete lastRequest.receptionVenue;
		delete lastRequest.requireCeremonyVenue;
	}

	if (lastRequest.weddingDate) {
		output.xo_date_picker = {
			weddingDate: format(parseISO(lastRequest.weddingDate), 'yyyy-MM-dd'),
		};
		delete lastRequest.weddingDate;
	}

	if (lastRequest.message && lastRequest.message.body) {
		output.body = lastRequest.message.body;
		delete lastRequest.message;
	}

	// min/max props
	_lastRequestAssignMaxMinValues(lastRequest, properties, output);

	// single select questions w/o special cases
	filteredSingleSelectProperties.forEach((key) => {
		const input =
			key in lastRequest
				? lastRequest
				: lastRequest.details && key in lastRequest.details
				  ? lastRequest.details
				  : null;

		if (input) {
			const selected = _lastRequestFindByValue(properties, key, input[key]);
			if (selected) {
				output[key] = selected;
			}
			delete input[key];
		}
	});

	if (lastRequest.details) {
		// multi selects
		multiSelectProperties(ui).forEach((key) => {
			const matches = _lastRequestFindByValues(
				lastRequest.details,
				properties,
				key,
			);

			if (matches.length) {
				output[key] = matches;
			}

			delete lastRequest.details[key];
		});

		if ('endTime' in lastRequest.details && properties.end_time_picker) {
			const match = _lastRequestFindByValue(
				properties.end_time_picker.properties,
				'endTime',
				lastRequest.details.endTime,
			);

			Object.assign(output, {
				end_time_picker: {
					has_selection: !!match,
					endTime: match || { value: '' },
				},
			});

			delete lastRequest.details.endTime;
		}

		if ('blueCard' in lastRequest.details && properties.bluecard) {
			Object.assign(output, {
				bluecard: lastRequest.details.blueCard,
			});
			delete lastRequest.details.blueCard;
		}

		// move remaining details to root object
		Object.assign(output, lastRequest.details);
		delete lastRequest.details;
	}

	// merge remaining properties. these will be stripped further down the chain if not on the schema
	Object.assign(output, lastRequest);

	return output;
};

export { loadLastRequest };
