const getCategoryCode = (storefrontRaw) => {
	const categoryCodes =
		storefrontRaw.categories && storefrontRaw.categories.map((c) => c.code);
	return categoryCodes && categoryCodes.length && categoryCodes[0];
};

const getEnhancedRFQ = (storefrontRaw) => {
	if ('settings.enhancedRFQ' in storefrontRaw) {
		return storefrontRaw.settings &&
			storefrontRaw.settings.enhancedRFQ !== undefined
			? storefrontRaw.settings.enhancedRFQ
			: true;
	}

	return true;
};

const getMessageFormVersion = (storefrontRaw) => {
	return storefrontRaw.settings &&
		storefrontRaw.settings.messageFormVersion !== undefined
		? storefrontRaw.settings.messageFormVersion
		: null;
};

const getRFQFormVersion = (storefrontRaw) => {
	return storefrontRaw.settings &&
		storefrontRaw.settings.rfqFormVersion !== undefined
		? storefrontRaw.settings.rfqFormVersion
		: null;
};

const getPaid = (storefrontRaw) => {
	return storefrontRaw?.purchaseStatus === 'PAID';
};

const getFormConfigProps = (storefrontRaw) => {
	const categoryCode = getCategoryCode(storefrontRaw);
	return {
		paid: getPaid(storefrontRaw),
		enhancedRFQ: getEnhancedRFQ(storefrontRaw),
		rfqFormVersion: getRFQFormVersion(storefrontRaw),
		messageFormVersion: getMessageFormVersion(storefrontRaw),
		categoryCode,
	};
};

export {
	getCategoryCode,
	getEnhancedRFQ,
	getRFQFormVersion,
	getMessageFormVersion,
	getPaid,
	getFormConfigProps,
};
