import { CONFIGURATIONS, categoryHasHours } from '../rfqConfig/configurations';
import { getFormConfigProps } from '../rfqConfig/utils/storefrontRaw';

const buildSearchPaths = (props) => {
	const { searchPaths, key, formVersion, categoryCode } = props;

	searchPaths.unshift(`${key}.default`);

	if (formVersion) {
		searchPaths.unshift(`${key}.default_${formVersion}`);
	}

	searchPaths.unshift(`${key}.${categoryCode}`);

	if (formVersion) {
		searchPaths.unshift(`${key}.${categoryCode}_${formVersion}`);
	}
};

const configSearchPaths = (props) => {
	const {
		formType,
		withHours,
		categoryCode,
		rfqFormVersion,
		messageFormVersion,
	} = props;
	const searchPaths = [];

	buildSearchPaths({
		searchPaths,
		key: 'PAID',
		formVersion: null,
		categoryCode,
	});
	if (formType === 'MESSAGE') {
		buildSearchPaths({
			searchPaths,
			key: 'PAID.MESSAGE',
			formVersion: messageFormVersion,
			categoryCode,
		});
	} else if (formType === 'RFQ') {
		buildSearchPaths({
			searchPaths,
			key: 'PAID.RFQ',
			formVersion: rfqFormVersion,
			categoryCode,
		});
		if (withHours) {
			buildSearchPaths({
				searchPaths,
				key: 'PAID.RFQ.WITH_HOURS',
				formVersion: rfqFormVersion,
				categoryCode,
			});
			buildSearchPaths({
				searchPaths,
				key: 'PAID.RFQ.OPT_IN',
				formVersion: rfqFormVersion,
				categoryCode,
			});
			buildSearchPaths({
				searchPaths,
				key: 'PAID.RFQ.OPT_IN.WITH_HOURS',
				formVersion: rfqFormVersion,
				categoryCode,
			});
		} else {
			buildSearchPaths({
				searchPaths,
				key: 'PAID.RFQ.OPT_IN',
				formVersion: rfqFormVersion,
				categoryCode,
			});
		}
	}
	return searchPaths;
};

const LoadConfigByStorefront = (storefrontRaw, formType = 'RFQ') => {
	let config;
	const {
		categoryCode,
		paid,
		enhancedRFQ,
		rfqFormVersion,
		messageFormVersion,
	} = getFormConfigProps(storefrontRaw);

	const withHours = categoryHasHours(categoryCode);

	const searchPaths = configSearchPaths({
		paid,
		enhancedRFQ,
		rfqFormVersion,
		messageFormVersion,
		withHours,
		formType,
		categoryCode,
	});

	searchPaths.some((key) => {
		config = key
			.split('.')
			.reduce((obj, property) => obj && obj[property], CONFIGURATIONS);

		if (config) {
			return true;
		}

		return false;
	});

	config.vendorCategory = categoryCode;

	if (!config) {
		throw new Error(`${categoryCode} config not found`);
	}

	return config;
};

const LoadRfqByStorefront = (storefrontRaw) =>
	LoadConfigByStorefront(storefrontRaw, 'RFQ');

const LoadMessageByStorefront = (storefrontRaw) =>
	LoadConfigByStorefront(storefrontRaw, 'MESSAGE');

export { LoadRfqByStorefront, LoadMessageByStorefront, LoadConfigByStorefront };

export default LoadConfigByStorefront;
